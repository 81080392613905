import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel } from "swiper";
import { useInView } from 'react-intersection-observer';


import { motion, useAnimation } from 'framer-motion'

import "swiper/css";
import "swiper/css/pagination";

import "./KeyPoints.css";
import { ForChefsIcon, ForClientsIcon, ForCourersIcon, LikeIcon } from "../../../assets/icons/icons";

import clients_card_0 from '../../../assets/images/main/key-points/Clients0.png'
import clients_card_1 from '../../../assets/images/main/key-points/Clients1.png'
import clients_card_2 from '../../../assets/images/main/key-points/Clients2.png'

import cook_card_0 from '../../../assets/images/main/key-points/Cook0.png'
import cook_card_1 from '../../../assets/images/main/key-points/Cook1.png'
import cook_card_2 from '../../../assets/images/main/key-points/Cook2.png'

import courier_card_0 from '../../../assets/images/main/key-points/Courier0.png'
import courier_card_1 from '../../../assets/images/main/key-points/Courier1.png'
import courier_card_2 from '../../../assets/images/main/key-points/Courier2.png'
import useWindowSize from "../../../assets/hooks/useWindowSize";

const controlVariants = {
    initial: { opacity: 0, y: -50 },
    animate: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const cardVariants = {
    initial: { opacity: 0, scale: 0.95 },
    animate: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
};

const controls = [
    {
        title: 'Для клиентов',
        short_title: 'Клиентам',
        icon: ForClientsIcon,
        cards: [
            {
                title: `Быстрая 
доставка`,
                text: 'Заказывайте любимые блюда, и в течение двух часов они окажутся на вашем столе.',
                img: clients_card_0,
            },
            {
                title: `Разнообразие 
выбора`,
                text: 'Широкий ассортимент домашних кулинарных шедевров, отсортированных по популярности, кухне, повару и рейтингу.',
                img: clients_card_1,
            },
            {
                title: `Прозрачность 
и доверие`,
                text: 'Открывайте для себя новые блюда с детальным описанием, фотографиями и отзывами от других клиентов.',
                img: clients_card_2,
            },
        ],
        like: 'Заказывайте любимые блюда домашней кухни в любую точку города благодаря платформе «Мой шеф»',
    },
    {
        title: 'Для поваров',
        short_title: 'Поварам',
        icon: ForChefsIcon,
        cards: [
            {
                title: `Всё 
включено`,
                text: 'Мы предоставляем все необходимое для упаковки и доставки ваших блюд, позволяя вам сосредоточиться на кулинарии.',
                img: cook_card_0,
            },
            {
                title: `Гибкость 
и самостоятельность`,
                text: 'Создавайте личный график работы, выбирайте, какие заказы принимать, и управляйте своим меню через удобное приложение.',
                img: cook_card_1,
            },
            {
                title: `Поддержка 
и развитие`,
                text: 'Помогаем вам расти: продвигаем профиль среди аудитории и предлагаем выгодные условия сотрудничества.',
                img: cook_card_2,
            },
        ],
        like: 'Расширьте аудиторию любителей ваших домашних блюд благодаря платформе «Мой шеф»',
    },

    {
        title: 'Для курьеров',
        short_title: 'Курьерам',
        icon: ForCourersIcon,
        cards: [
            {
                title: `Гибкость 
в работе`,
                text: 'Работайте по собственному графику, выбирая слоты, которые удобны именно вам.',
                img: courier_card_0,
            },
            {
                title: `Достойный 
заработок`,
                text: 'Предлагаем конкурентную оплату до 650₽ в час, чаевые от клиентов, а также скидки на еду с нашей платформы.',
                img: courier_card_1,
            },
            {
                title: `Легкий 
старт`,
                text: 'Заполните анкету и пройдите краткое обучение. После этого вы сразу можете начать доставлять заказы.',
                img: courier_card_2,
            },
        ],
        like: 'Создайте удобный рабочий график благодаря платформе «Мой шеф», не потеряв при этом доход',
    },
]

function KeyPoints() {
    const controlsAnimation = useAnimation();
    const [selected, setSelected] = useState(controls[1])
    const { width } = useWindowSize()

    const [ref, inView] = useInView({
        triggerOnce: true, // Trigger animation only once
        rootMargin: '-100px', // Trigger the animation before the element comes 100px into view
    });

    useEffect(() => {
        if (inView) {
            controlsAnimation.start('animate');
        }
    }, [inView, controlsAnimation]);


    return (
        <div className="key-points"  ref={ref}>
            <div className="key-points__container">
                <div className="key-points__heading">
                    <h3 className="key-points__heading-title">Ключевые <br />преимущества:</h3>
                    <div className="key-points__heading-controls">
                        {controls.map((item, i) => (
                            <motion.div
                                className={`key-points__heading-control ${selected.title === item.title ? 'key-points__heading-control-selected' : ''}`}
                                key={`key-points__heading-control-${i}`}
                                variants={controlVariants}
                                initial="initial"
                                animate="animate"
                            >
                                <item.icon
                                    mainClassName={`key-points__heading-control-icon`}
                                    strokeClassName={'key-points__heading-control-icon-stroke'}
                                />
                                <button className="key-points__heading-control-btn" type="button" onClick={() => {
                                    setSelected(item)
                                }}>
                                    {width > 800 ? item.title : item.short_title}
                                </button>
                            </motion.div>
                        ))}

                    </div>
                </div>
                <div className="key-points__cards-container">
                    {width > 880 ?
                        <div className="key-points__cards">
                            {
                                selected.cards.map((item, i) => (
                                    <motion.div
                                        className={`key-points__card ${i === 1 ? 'key-points__card_big' : ''}`}
                                        key={`${selected.title}-card-${i}`}
                                        variants={cardVariants}
                                        initial="initial"
                                        animate="animate"
                                    >
                                        <img className={`key-points__card-img`} src={item.img} alt="" />
                                        <svg className={`key-points__card-bg-shape key-points__card-bg-shape_${i}`} width="392" height="392" viewBox="0 0 392 392" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="196" cy="196" r="196" fill="#F1F8FF" />
                                        </svg>
                                        <p className="key-points__card-index">{i + 1}</p>
                                        <h5 className="key-points__card-title">{item.title}</h5>
                                        <p className="key-points__card-text">{item.text}</p>
                                    </motion.div>
                                ))
                            }
                        </div>
                        :
                        <Swiper
                            slidesPerView={"auto"}
                            spaceBetween={12}
                            // pagination={{
                            //     clickable: true,
                            //     dynamicBullets: true,
                            //     dynamicMainBullets: 4,
                            // }}
                            mousewheel={{
                                forceToAxis: true,
                            }}
                            modules={[Mousewheel]}
                            // preventInteractionOnTransition={true}
                            className="key-points__cards"
                            key={`${selected.title}-swiper`}
                        >
                            {
                                selected.cards.map((item, i) => (
                                    <SwiperSlide className="key-points__card-slide" key={`${selected.title}-card-slide-${i}`}>
                                        <motion.div
                                            className={`key-points__card ${i === 1 ? 'key-points__card_big' : ''}`}
                                            variants={cardVariants}
                                            initial="initial"
                                            animate="animate"
                                        >
                                            <img className={`key-points__card-img`} src={item.img} alt="" />
                                            <svg className={`key-points__card-bg-shape key-points__card-bg-shape_${i}`} width="392" height="392" viewBox="0 0 392 392" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="196" cy="196" r="196" fill="#F1F8FF" />
                                            </svg>
                                            <p className="key-points__card-index">{i + 1}</p>
                                            <h5 className="key-points__card-title">{item.title}</h5>
                                            <p className="key-points__card-text">{item.text}</p>
                                        </motion.div>

                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                    }
                </div>
                <div className="key-points__like">
                    <LikeIcon
                        mainClassName={'key-points__like-icon'}
                        strokeClassName={'key-points__like-icon-stroke'}
                    />
                    <p className="key-points__like-text">{selected.like}</p>
                </div>
            </div>
        </div>
    );
}

export default KeyPoints;
