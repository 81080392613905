import "./Banner.css";
import city from '../../../assets/images/main/bnr/city.png'
import pc from '../../../assets/images/main/bnr/pc.png'
import mobile from '../../../assets/images/main/bnr/mobile.png'

function Banner() {
    return (
        <div className="banenr">
            <div className="banenr__container">
                <div className="banenr__contnent">
                    <img className="banenr__icon banenr__icon_pc" src={pc} alt="" />
                    <img className="banenr__icon banenr__icon_mobile" src={mobile} alt="" />
                </div>
                <img className="banenr__bg" src={city} alt="" />
            </div>
        </div>
    );
}

export default Banner;
