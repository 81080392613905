import "./Footer.css";

import logo from '../../assets/images/main/footer/logo.png'

function Footer() {
  return (
    <footer className="footer">
      <div className="footer__container">
        <img className="footer__logo" src={logo} alt="" />
        <p className="footer__copyright">Ⓒ Все права защищены. Мой шеф – 2024</p>
      </div>
    </footer>
  );
}

export default Footer;
