import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';


import { getRandomId } from '../../../assets/utils/utils';
import './FAQ.css';

import { AnswerIcon, DropArrowIcon } from '../../../assets/icons/icons';

const QUESTIONS = [
    {
        _id: getRandomId(),
        question: 'Гарантируете&nbsp;ли вы&nbsp;качество еды?',
        answer: 'Разумеется. Мы&nbsp;тщательно отбираем поваров для сотрудничества и&nbsp;регулярно контролируем качество блюд. В&nbsp;случае возникновения проблем с&nbsp;заказом, пожалуйста, немедленно свяжитесь с&nbsp;нами для решения вопроса.'
    },
    {
        _id: getRandomId(),
        question: 'Могу&nbsp;ли я&nbsp;заказать доставку в&nbsp;любое время дня?',
        answer: 'Доставка доступна в&nbsp;рамках рабочих часов наших поваров и&nbsp;курьеров. Время работы различается в&nbsp;зависимости от&nbsp;конкретного повара и&nbsp;вашего региона, однако мы&nbsp;стараемся обеспечить максимально удобный диапазон времени для заказов.'
    },
    {
        _id: getRandomId(),
        question: 'Как я&nbsp;могу узнать статус своего заказа?',
        answer: 'Статус заказа можно отслеживать в&nbsp;нашем приложении в&nbsp;реальном времени. Вы&nbsp;также получите уведомления об&nbsp;основных этапах доставки.'
    },
    {
        _id: getRandomId(),
        question: 'Что делать, если я&nbsp;хочу отменить заказ?',
        answer: 'Отмена заказа возможна через приложение или сайт до&nbsp;того момента, как повар начал его приготовление. В&nbsp;случае необходимости отмены после этого момента, пожалуйста, свяжитесь с&nbsp;нашей службой поддержки.'
    },
    {
        _id: getRandomId(),
        question: 'Могу&nbsp;ли я&nbsp;оставить отзыв о&nbsp;блюде или сервисе доставки?',
        answer: 'Да, мнение клиентов очень важно для нас! После доставки заказа вы&nbsp;можете оставить отзыв и&nbsp;оценку через приложение. Мы&nbsp;ценим обратную связь, так как она помогает улучшать качество наших услуг.'
    },
    {
        _id: getRandomId(),
        question: 'Сколько я&nbsp;могу заработать, работая поваром?',
        answer: 'Заработок поваров не&nbsp;ограничен и&nbsp;зависит от&nbsp;популярности их&nbsp;блюд и&nbsp;количества заказов.'
    },
    {
        _id: getRandomId(),
        question: 'Сколько я&nbsp;могу заработать, работая курьером?',
        answer: 'Заработок курьеров зависит от&nbsp;количества выполненных доставок и&nbsp;выбранного ими графика работы. Наши курьеры зарабатывают до&nbsp;650&nbsp;₽ в&nbsp;час, а&nbsp;также получают чаевые от&nbsp;клиентов.'
    },
]

function FAQ() {

    const [openItemId, setOpenItemId] = useState(QUESTIONS[0]._id);

    const toggleItem = (id) => {
        if (openItemId === id) {
            setOpenItemId(null);
        } else {
            setOpenItemId(id);
        }
    };

    return (
        <div className='faq'>
            <h4 className='faq__title'>Часто задаваемые вопросы</h4>
            <div className='faq__items'>
                {QUESTIONS.map((item, i) => (
                    <button className={`faq__item ${openItemId === item._id ? 'faq__item_selected' : ''}`} type='button' key={`faq__item${item._id}`} onClick={() => toggleItem(item._id)}>
                        <div className='faq__item-info'>
                            <p className='faq__item-info-question'
                                dangerouslySetInnerHTML={{
                                    __html: item.question,
                                }}
                            ></p>
                            <AnimatePresence mode='sync'>
                                {openItemId === item._id && (
                                    <motion.div
                                        initial={{ opacity: 0, height: 0, y: -16, }}
                                        animate={{ opacity: 1, height: 'fit-content', y: 0, }}
                                        exit={{ opacity: 0, height: 0, y: -16, }}
                                        transition={{ duration: 0.3 }}
                                        className='faq__item-info-answer-box'
                                    >
                                        <AnswerIcon
                                            mainClassName={'faq__item-info-answer-icon'}
                                        />
                                        <p className='faq__item-info-answer'
                                            dangerouslySetInnerHTML={{
                                                __html: item.answer,
                                            }}
                                        ></p>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                        <DropArrowIcon
                            mainClassName={'faq__item-arrow'}
                            strokeClassName={'faq__item-arrow-stroke'}
                        />
                    </button>
                ))}

            </div>
        </div>
    );
}

export default FAQ