import "./SwitchSelector.css";

function SwitchSelector({ list, onSelect, selectedType, type }) {
  return (
    <div className={`switch ${type ? `switch_type_${type}` : ""}`}>
      <ul className="switch__list">
        {list.map((item) => (
          <li className="switch__item" key={item.value}>
            <button
              className={`switch__select-btn ${
                item.value === selectedType ? "switch__select-btn_selected" : ""
              }`}
              type="button"
              onClick={() => onSelect(item)}
            >
              {item.icon ? item.icon : null}
              <p className="switch__btn-text">{item.name}</p>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SwitchSelector;
