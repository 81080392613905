import { motion } from "framer-motion";
import { LogoIcon, MapIcon } from "../../../assets/icons/icons";
import img1 from "../../../assets/images/main/heading/img1.webp";
import img2 from "../../../assets/images/main/heading/img2.webp";
import img3 from "../../../assets/images/main/heading/img3.webp";
import img4 from "../../../assets/images/main/heading/img4.webp";
import img5 from "../../../assets/images/main/heading/img5.webp";
import line1 from "../../../assets/images/main/heading/line1.png";
import line2 from "../../../assets/images/main/heading/line2.png";
import line3 from "../../../assets/images/main/heading/line3.png";
import line4 from "../../../assets/images/main/heading/line4.png";
import "./Heading.css";
import { useContext } from "react";
import { PopupContext } from "../../../assets/contexts/popupContext";

function Heading() {
  const { toggleFormPopup } = useContext(PopupContext)
  return (
    <div className="heading">
      <div className="heading__container">
        <div className="heading__content">
          <LogoIcon
            mainClassName="heading__logo"
            fillClassName="heading__logo-fill"
            bgClassName="heading__logo-bg"
          />
          <div className="heading__title-box">
            <h1 className="heading__title">
              Вкус дома в&nbsp;любой&nbsp;точке города
            </h1>
            <MapIcon
              mainClassName="heading__map-icon"
              fillClassName="heading__map-icon-fill"
              secondaryFillClassName="heading__map-icon-secondary-fill"
            />
          </div>
          <p className="heading__text">
            Совершите революцию в доставке еды. Приглашаем к сотрудничеству
            поваров и&nbsp;курьеров.
          </p>
          <motion.button
            className="heading__btn"
            type="button"
            onClick={toggleFormPopup}
            initial={{ background: "var(--background-color)" }}
            whileHover={{ background: "var(--btn-on-contrast-hover-color)" }}
            whileTap={{ background: "var(--btn-on-contrast-active-color)" }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
          >
            Стать партнером
          </motion.button>
        </div>

        <div className="heading__bg-circle heading__bg-circle_type_big" />

        <div className="heading__img-box heading__img-box_type_top-left">
          <img className="heading__img" src={img1} alt="" />
          <div className="heading__line-circle heading__line-circle_type_small" />
          <div className="heading__line-circle heading__line-circle_type_big" />
          <div className="heading__bg-circle heading__bg-circle_type_top-left" />
          <img
            className="heading__line heading__line_type_short"
            src={line2}
            alt=""
          />
          <img
            className="heading__line heading__line_type_short-mobile"
            src={line3}
            alt=""
          />
        </div>

        <div className="heading__img-box heading__img-box_type_top-right">
          <img className="heading__img" src={img2} alt="" />
          <div className="heading__bg-circle heading__bg-circle_type_top-right" />
        </div>

        <div className="heading__img-box heading__img-box_type_bottom-right">
          <img className="heading__img" src={img3} alt="" />
          <div className="heading__line-circle heading__line-circle_type_small" />
          <div className="heading__bg-circle heading__bg-circle_type_bottom-right" />
          <img
            className="heading__line heading__line_type_long"
            src={line1}
            alt=""
          />
          <img
            className="heading__line heading__line_type_long-mobile"
            src={line4}
            alt=""
          />
        </div>

        <div className="heading__img-box heading__img-box_type_bottom-left">
          <img className="heading__img" src={img5} alt="" />
          <div className="heading__bg-circle heading__bg-circle_type_bottom-left" />
        </div>

        <div className="heading__img-box heading__img-box_type_center">
          <img className="heading__img" src={img4} alt="" />
          <div className="heading__bg-circle heading__bg-circle_type_center" />
        </div>
      </div>
    </div>
  );
}

export default Heading;
