import { useState } from "react";
import "./CustomInput.css";

function CustomInput({
  label,
  value,
  name,
  type = "text",
  inputMode = "text",
  placeholder = "",
  onChange,
  onRemove,
  isRequired = true,
  readOnly = false,
  error,
}) {
  const [isFocuced, setIsFocuced] = useState(false);

  function handleFocused() {
    setIsFocuced(true);
  }

  function handleUnfocused(evt) {
    const value = evt.target.value;
    Boolean(value) ? setIsFocuced(true) : setIsFocuced(false);
  }

  return (
    <div
      className={`custom-input ${
        isFocuced || Boolean(value) ? "custom-input_focused" : ""
      }`}
    >
      {label ? (
        <label className="custom-input__label" htmlFor={name}>
          {label}
        </label>
      ) : null}

      <input
        className={`custom-input__input`}
        id={name}
        name={name}
        type={type}
        inputMode={inputMode}
        placeholder={placeholder}
        value={value || ""}
        required={isRequired}
        readOnly={readOnly}
        autoComplete="off"
        onChange={onChange}
        onKeyDown={onRemove ? onRemove : undefined}
        onClick={!readOnly ? handleFocused : undefined}
        onFocus={!readOnly ? handleFocused : undefined}
        onBlur={handleUnfocused}
      />

      {Boolean(error?.errorMessage) ? (
        <span className="custom-input__error">{error?.errorMessage}</span>
      ) : null}
    </div>
  );
}

export default CustomInput;
