import Banner from "./Banner/Banner";
import FAQ from "./FAQ/FAQ";
import Heading from "./Heading/Heading";
import HowConenct from "./HowConenct/HowConenct";
import KeyPoints from "./KeyPoints/KeyPoints";
import "./Main.css";
import OnHome from "./OnHome/OnHome";

function Main() {
  return (
    <main className="main">
      <Heading />
      <OnHome />
      <KeyPoints />
      <HowConenct />
      <Banner />
      <FAQ />
    </main>
  );
}

export default Main;
