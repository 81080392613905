import { useContext, useEffect, useState } from "react";



import { motion, useAnimation } from 'framer-motion'



import "./HowConenct.css";
import { AgreementIcon, ForChefsIcon, ForClientsIcon, ForCourersIcon, GetBagIcon, LearnIcon, LeaveAppealIcon, LikeIcon, ScooterIcon, StartCookIcon, StartDeliverIcon } from "../../../assets/icons/icons";
import { PopupContext } from "../../../assets/contexts/popupContext";


const controlVariants = {
    initial: { opacity: 0, y: -50 },
    animate: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const cardVariants = {
    initial: { opacity: 0, scale: 0.95 },
    animate: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
};

const controls = [
    {
        title: 'Для поваров',
        cards: [
            {
                title: `Оставьте заявку`,
                text: 'Заполните онлайн-форму на&nbsp;нашем&nbsp;сайте',
                icon: LeaveAppealIcon,
            },
            {
                title: `Заключите договор`,
                text: 'Наш менеджер свяжется с&nbsp;вами для обсуждения условий и&nbsp;заключения договора на&nbsp;основании оферты',
                icon: AgreementIcon,
            },
            {
                title: `Пройдите обучение`,
                text: 'Вы получите обучающие материалы для работы на&nbsp;платформе, заполните меню и&nbsp;настроите свой график',
                icon: LearnIcon,
            },
            {
                title: `Начните работу`,
                text: 'Вы начнете получать заказы и&nbsp;готовить блюда, а&nbsp;о&nbsp;доставке мы&nbsp;позаботимся',
                icon: StartCookIcon,
            },
        ],
    },

    {
        title: 'Для курьеров',
        cards: [
            {
                title: `Оставьте заявку`,
                text: 'Заполните онлайн-форму на&nbsp;нашем&nbsp;сайте',
                icon: LeaveAppealIcon,
            },
            {
                title: `Заключите договор`,
                text: 'Наш менеджер свяжется с&nbsp;вами для обсуждения условий и&nbsp;заключения договора на&nbsp;основании оферты',
                icon: AgreementIcon,
            },
            {
                title: `Пройдите обучение`,
                text: 'Вы получите обучающие материалы для работы на&nbsp;платформе, термосумку и&nbsp;другие аксессуары',
                icon: GetBagIcon,
            },
            {
                title: `Начните работу`,
                text: 'Вы начнете принимать заказы и&nbsp;доставлять&nbsp;их, получая зарплату и&nbsp;чаевые',
                icon: StartDeliverIcon,
            },
        ],
    },
]

function HowConenct() {
    const [selected, setSelected] = useState(controls[0])
    const { toggleFormPopup } = useContext(PopupContext)


    return (
        <div className="how-conenct" >
            <div className="how-conenct__container">
                <svg className="how-conenct__bg-shape-1" width="710" height="710" viewBox="0 0 710 710" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="355" cy="355" r="355" fill="#F1F8FF" />
                </svg>

                <div className="how-conenct__heading">
                    <h3 className="how-conenct__heading-title">Как к нам <br />присоединиться?</h3>
                    <div className="how-conenct__heading-line">
                        <ScooterIcon
                            mainClassName={'how-conenct__heading-line-icon'}
                            strokeClassName={'how-conenct__heading-line-icon-stroke'}
                        />
                        <div className="how-conenct__heading-line-elem"></div>
                    </div>
                    <div className="how-conenct__heading-controls">
                        {controls.map((item, i) => (
                            <motion.div
                                className={`how-conenct__heading-control ${selected.title === item.title ? 'how-conenct__heading-control-selected' : ''}`}
                                key={`how-conenct__heading-control-${i}`}
                                variants={controlVariants}
                                initial="initial"
                                animate="animate"
                            >
                                <button className="how-conenct__heading-control-btn" type="button" onClick={() => {
                                    setSelected(item)
                                }}>
                                    {item.title}
                                </button>
                            </motion.div>
                        ))}

                    </div>
                </div>
                <div className="how-conenct__cards-container">
                    <div className="how-conenct__cards">
                        {
                            selected.cards.map((item, i) => (
                                <motion.div
                                    className={`how-conenct__card `}
                                    key={`${selected.title}-card-${i}`}
                                    variants={cardVariants}
                                    initial="initial"
                                    animate="animate"
                                >
                                    <div className={`how-conenct__card-icon-box ${i === 3 ? 'how-conenct__card-icon-box_last' : ''}`}>
                                        <item.icon
                                            mainClassName={'how-conenct__card-icon'}
                                            strokeClassName={'how-conenct__card-icon-stroke'}
                                        />
                                    </div>
                                    <div className="how-conenct__card-texts">
                                        <h4 className="how-conenct__card-title">{item.title}</h4>
                                        <p className="how-conenct__card-text" dangerouslySetInnerHTML={{ __html: item.text }}></p>
                                    </div>

                                </motion.div>
                            ))
                        }
                    </div>
                </div>
                <motion.button
                    className="how-conenct__btn"
                    type="button"
                    onClick={toggleFormPopup}
                    initial={{ background: "var(--accent-color)" }}
                    whileHover={{ background: "var(--btn-hover-color)" }}
                    whileTap={{ background: "var(--btn-active-color)" }}
                    transition={{ duration: 0.2, ease: "easeInOut" }}
                >
                    Оставить заявку
                </motion.button>
            </div>
        </div>
    );
}

export default HowConenct;
