import { useState } from "react";
import Footer from "../Footer/Footer";
import FormPopup from "../FormPopup/FormPopup";
import Main from "../Main/Main";
import "./App.css";
import { PopupContext } from "../../assets/contexts/popupContext";
import { Route, Routes } from "react-router-dom";

function App() {
  const [isFormPopupOpen, setIsFormPopupOpen] = useState(false);

  function toggleFormPopup() {
    setIsFormPopupOpen((prevState) => !prevState);
  }

  return (
    <div className="app">
      <PopupContext.Provider value={{
        toggleFormPopup: toggleFormPopup,
        isOpen: isFormPopupOpen,
      }}>
        <Routes>
          <Route index element={<Main />}></Route>
        </Routes>
        <Footer />
        <FormPopup />
      </PopupContext.Provider>

    </div>
  );
}

export default App;
