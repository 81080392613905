import { useContext, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SwitchSelector from "../SwitchSelector/SwitchSelector";
import CustomInput from "../CustomInput/CustomInput";
import {
  CLoseIcon,
  CookTypeIcon,
  CourierTypeIcon,
} from "../../assets/icons/icons";
import {
  COOK_TYPE_VALUE,
  FORM_COOK_TYPE,
  FORM_COURIER_TYPE,
  FORM_POPUP_STEP,
  SUCCESS_POPUP_STEP,
} from "../../assets/utils/constants";
import "./FormPopup.css";
import { PopupContext } from "../../assets/contexts/popupContext";

const TYPES_LIST = [
  {
    ...FORM_COOK_TYPE,
    icon: (
      <CookTypeIcon
        mainClassName="switch__btn-icon"
        strokeClassName="switch__btn-icon-stroke"
      />
    ),
  },
  {
    ...FORM_COURIER_TYPE,
    icon: (
      <CourierTypeIcon
        mainClassName="switch__btn-icon"
        strokeClassName="switch__btn-icon-stroke"
      />
    ),
  },
];

const initialValues = {
  type: COOK_TYPE_VALUE,
  name: "",
  phone: "",
};

const initialValidity = {
  name: {},
  phone: {},
};

function FormPopup() {
  const { toggleFormPopup, isOpen } = useContext(PopupContext)

  const [step, setStep] = useState(FORM_POPUP_STEP);
  const [values, setValues] = useState(initialValues);
  const [valuesValidity, setValuesValidity] = useState(initialValidity);
  const isDisabled = !(
    valuesValidity.name.validState && valuesValidity.phone.validState
  );

  function handleChange(evt) {
    const input = evt.target;
    const name = input.name;
    const value = input.value;

    switch (name) {
      case "phone": {
        let inputValue = value.replace(/\D/g, "");
        let formattedInputValue = "";
        if (!inputValue) {
          setValues((prevValue) => ({
            ...prevValue,
            [name]: "",
          }));
          setValuesValidity((prevValue) => ({
            ...prevValue,
            [name]: {
              errorMessage: "Only numbers allowed",
              validState: false,
            },
          }));
        } else {
          if (["7", "8", "9"].indexOf(inputValue[0]) > -1) {
            setValuesValidity((prevValue) => ({
              ...prevValue,
              [name]: {
                errorMessage: "",
                validState: false,
              },
            }));
            if (inputValue[0] === "9") inputValue = "7" + inputValue;

            let firstSimbols = inputValue[0] === "8" ? "8" : "+7";
            formattedInputValue = firstSimbols + " ";
            if (inputValue.length > 1) {
              formattedInputValue += "(" + inputValue.substring(1, 4);
            }
            if (inputValue.length >= 5) {
              formattedInputValue += ") " + inputValue.substring(4, 7);
            }
            if (inputValue.length >= 8) {
              formattedInputValue += "-" + inputValue.substring(7, 9);
            }
            if (inputValue.length >= 10) {
              formattedInputValue += "-" + inputValue.substring(9, 11);
            }
            if (inputValue.length >= 11) {
              setValuesValidity((prevValue) => ({
                ...prevValue,
                [name]: {
                  errorMessage: "",
                  validState: true,
                },
              }));
            } else {
              setValuesValidity((prevValue) => ({
                ...prevValue,
                [name]: {
                  errorMessage: "",
                  validState: false,
                },
              }));
            }
          } else {
            formattedInputValue = "+" + inputValue.substring(0, 16);
            if (inputValue.length >= 11) {
              setValuesValidity((prevValue) => ({
                ...prevValue,
                [name]: {
                  errorMessage: "",
                  validState: true,
                },
              }));
            } else {
              setValuesValidity((prevValue) => ({
                ...prevValue,
                [name]: {
                  errorMessage: "",
                  validState: false,
                },
              }));
            }
          }

          setValues((prevValue) => ({
            ...prevValue,
            [name]: formattedInputValue,
          }));
        }
        break;
      }

      case "phone-delete": {
        const keyCode = input.keyCode;
        const formattedValue = value.replace(/\D/g, "");
        if (keyCode === 8 && formattedValue.length === 1) {
          setValues((prevValue) => ({
            ...prevValue,
            phone: "",
          }));
        }
        if (keyCode === 8 && formattedValue.length < 11) {
          setValuesValidity((prevValue) => ({
            ...prevValue,
            phone: {
              errorMessage: "",
              validState: false,
            },
          }));
        }
        break;
      }

      default: {
        setValues((prevVal) => ({ ...prevVal, [name]: value }));
        setValuesValidity((prevVal) => ({
          ...prevVal,
          [name]: {
            errorMessage: "",
            validState: Boolean(value),
          },
        }));
      }
    }
  }

  function handleClose() {
    toggleFormPopup();
    setValues(initialValues);
    setValuesValidity(initialValidity);
    setStep(FORM_POPUP_STEP);
  }


  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(values);
    
    setStep(SUCCESS_POPUP_STEP);

    const botToken = '7123165008:AAE-kDJTb0tnI-4vgv26OlR_IPnpxcPXVL0'; // WARNING: Exposing token
    const chatId = '-4148389183';
    const text = `________
    НОВАЯ ЗАЯВКА
    Тип: ${values.type}
    Имя: ${values.name}
    Телефон: ${values.phone}
    ________`;

    try {
      const response = await fetch(`https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${chatId}&text=${encodeURIComponent(text)}`);
      const data = await response.json();
      console.log(data);
      // Handle success (e.g., clear form, show success message)
    } catch (error) {
      console.error('Error:', error);
      // Handle error
    }
  };

  return (
    <div
      className={`popup ${isOpen ? "popup_opened" : ""}`}
      onClick={handleClose}
    >
      <div className="popup__container" onClick={(e) => e.stopPropagation()}>
        <AnimatePresence initial={false} mode="wait">
          {step === FORM_POPUP_STEP && (
            <motion.div
              className="popup__form-block"
              key={FORM_POPUP_STEP}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2, ease: "easeInOut" }}
            >
              <button
                className="popup__close-btn"
                type="button"
                aria-label="Close popup"
                onClick={handleClose}
              >
                <CLoseIcon
                  mainClassName="popup__close-icon"
                  fillClassName="popup__close-icon-fill"
                />
              </button>
              <p className="popup__title">Заявка на&nbsp;сотрудничество</p>
              {/* <p className="popup__text">
                Станьте частью уникального сообщества,&nbsp;где каждый ценит
                культуру домашнего питания, качественный сервис и хорошее
                отношение к сотрудникам. Присоединяйтесь к «Моему&nbsp;шефу»!
              </p> */}

              <form className="popup__form" onSubmit={handleSubmit}>
                <SwitchSelector
                  list={TYPES_LIST}
                  selectedType={values.type}
                  type="form"
                  onSelect={(e) => {
                    handleChange({
                      target: {
                        value: e.value,
                        name: "type",
                      },
                    });
                  }}
                />
                <CustomInput
                  label="Имя"
                  value={values.name}
                  name="name"
                  onChange={handleChange}
                // error={valuesValidity.name}
                />
                <CustomInput
                  label="Телефон"
                  value={values.phone}
                  name="phone"
                  type="tel"
                  inputMode="tel"
                  onChange={handleChange}
                  onRemove={(e) => {
                    handleChange({
                      target: {
                        value: e.target.value,
                        name: "phone-delete",
                        keyCode: e.keyCode,
                      },
                    });
                  }}
                // error={valuesValidity.phone}
                />
                <motion.button
                  className={`popup__form-btn ${isDisabled ? "popup__form-btn_disabled" : ""
                    }`}
                  type="submit"
                  initial={{
                    background: "var(--btn-inactive-color)",
                    color: "var(--text-on-contrast-color)",
                  }}
                  animate={{
                    background: isDisabled
                      ? "var(--btn-inactive-color)"
                      : "var(--accent-color)",
                    color: isDisabled
                      ? "var(--text-on-contrast-color)"
                      : "var(--text-color)",
                  }}
                  whileHover={{ background: "var(--btn-hover-color)" }}
                  whileTap={{ background: "var(--btn-active-color)" }}
                  transition={{ duration: 0.2, ease: "easeInOut" }}
                >
                  Отправить
                </motion.button>
              </form>
            </motion.div>
          )}

          {step === SUCCESS_POPUP_STEP && (
            <motion.div
              className="popup__result-block"
              key={SUCCESS_POPUP_STEP}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2, ease: "easeInOut" }}
            >
              <div className="popup__bg-circle" />
              <div className="popup__img-box">
                {values.type === COOK_TYPE_VALUE ? (
                  <CookTypeIcon
                    mainClassName="popup__result-icon"
                    strokeClassName="popup__result-icon-stroke"
                  />
                ) : (
                  <CourierTypeIcon
                    mainClassName="popup__result-icon"
                    strokeClassName="popup__result-icon-stroke"
                  />
                )}
              </div>
              <p className="popup__title">Ваша заявка отправлена!</p>
              <p className="popup__text">
                Наши менеджеры свяжутся с вами в&nbsp;ближайшее&nbsp;время.
              </p>
              <motion.button
                className="popup__form-btn"
                type="button"
                onClick={handleClose}
                initial={{ background: "var(--accent-color)" }}
                whileHover={{ background: "var(--btn-hover-color)" }}
                whileTap={{ background: "var(--btn-active-color)" }}
                transition={{ duration: 0.2, ease: "easeInOut" }}
              >
                OK
              </motion.button>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default FormPopup;
