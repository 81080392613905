import "./OnHome.css";
import cap from '../../../assets/images/main/on-home/cap.png'
import { GoodQualityIcon, HomeCusineIcon, LampIcon, ReciptIcon } from "../../../assets/icons/icons";
import useWindowSize from "../../../assets/hooks/useWindowSize";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel } from "swiper";


// import { motion } from 'framer-motion'

import "swiper/css";
import "swiper/css/pagination";

function OnHome() {
    const { width } = useWindowSize()
    return (
        <div className="on-home">
            <div className="on-home__container">
                <svg className="on-home__bg-cirlce on-home__bg-cirlce_1" width="1226" height="631" viewBox="0 0 1226 631" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="613" cy="18" r="612" stroke="#FFC700" stroke-linejoin="round" stroke-dasharray="10 10" />
                </svg>

                <svg className="on-home__bg-cirlce on-home__bg-cirlce_2" width="1226" height="1226" viewBox="0 0 1226 1226" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="613" cy="613" r="612" stroke="var(--accent-color)" stroke-linejoin="round" stroke-dasharray="10 10" />
                </svg>

                <div className="on-home__text-banner">
                    <p className="on-home__text-banner-title">Мой шеф — </p>
                    <p className="on-home__text-banner-subtitle">это на домашнем</p>
                </div>
                <img className="on-home__cap" src={cap} alt="" />
                <LampIcon
                    mainClassName={'on-home__lamp-icon'}
                    strokeClassName={'on-home__lamp-icon-stroke'}
                />
                <div className="on-home__accent-and-rethought">
                    <p className="on-home__rethought">Мы&nbsp;переосмыслили подход к&nbsp;блюдам на&nbsp;заказ, сделав акцент&nbsp;на:</p>
                    {width > 800 ?
                        <div className="on-home__accent">
                            <div className="on-home__accent-card">
                                <div className="on-home__accent-card-icon-box">
                                    <HomeCusineIcon
                                        mainClassName={'on-home__accent-card-icon'}
                                        strokeClassName={'on-home__accent-card-icon-stroke'}
                                    />
                                </div>
                                <p className="on-home__accent-card-title">Домашней <br />кулинарии</p>
                            </div>
                            <div className="on-home__accent-card">
                                <div className="on-home__accent-card-icon-box">
                                    <GoodQualityIcon
                                        mainClassName={'on-home__accent-card-icon'}
                                        strokeClassName={'on-home__accent-card-icon-stroke'}
                                    />
                                </div>
                                <p className="on-home__accent-card-title">Высоком <br />качестве</p>
                            </div>
                            <div className="on-home__accent-card">
                                <div className="on-home__accent-card-icon-box">
                                    <ReciptIcon
                                        mainClassName={'on-home__accent-card-icon'}
                                        strokeClassName={'on-home__accent-card-icon-stroke'}
                                    />
                                </div>
                                <p className="on-home__accent-card-title">Рецептах <br />на любой вкус</p>
                            </div>
                        </div>
                        :

                        <Swiper
                            slidesPerView={"auto"}
                            spaceBetween={12}
                            // pagination={{
                            //     clickable: true,
                            //     dynamicBullets: true,
                            //     dynamicMainBullets: 4,
                            // }}
                            mousewheel={{
                                forceToAxis: true,
                            }}
                            modules={[Mousewheel]}
                            // preventInteractionOnTransition={true}
                            className="on-home__slides"
                        >
                            <SwiperSlide className="on-home__accent-card">
                                <div className="on-home__accent-card-icon-box">
                                    <HomeCusineIcon
                                        mainClassName={'on-home__accent-card-icon'}
                                        strokeClassName={'on-home__accent-card-icon-stroke'}
                                    />
                                </div>
                                <p className="on-home__accent-card-title">Домашней <br />кулинарии</p>
                            </SwiperSlide>
                            <SwiperSlide className="on-home__accent-card">
                                <div className="on-home__accent-card-icon-box">
                                    <GoodQualityIcon
                                        mainClassName={'on-home__accent-card-icon'}
                                        strokeClassName={'on-home__accent-card-icon-stroke'}
                                    />
                                </div>
                                <p className="on-home__accent-card-title">Высоком <br />качестве</p>
                            </SwiperSlide>
                            <SwiperSlide className="on-home__accent-card">
                                <div className="on-home__accent-card-icon-box">
                                    <ReciptIcon
                                        mainClassName={'on-home__accent-card-icon'}
                                        strokeClassName={'on-home__accent-card-icon-stroke'}
                                    />
                                </div>
                                <p className="on-home__accent-card-title">Рецептах <br />на любой вкус</p>
                            </SwiperSlide>
                        </Swiper>
                    }
                </div>
            </div>
        </div>
    );
}

export default OnHome;
