export const COOK_TYPE_VALUE = "cook";
export const COURIER_TYPE_VALUE = "courier";
export const CLIENT_TYPE_VALUE = "client";

export const FORM_COOK_TYPE = { value: COOK_TYPE_VALUE, name: "Повар" };
export const FORM_COURIER_TYPE = { value: COURIER_TYPE_VALUE, name: "Курьер" };

export const CLIENT_TYPE = { value: CLIENT_TYPE_VALUE, name: "Для поваров" };
export const COOK_TYPE = { value: COOK_TYPE_VALUE, name: "Для клиентов" };
export const COURIER_TYPE = { value: COURIER_TYPE_VALUE, name: "Для курьеров" };

export const FORM_POPUP_STEP = "form";
export const SUCCESS_POPUP_STEP = "success";
export const ERROR_POPUP_STEP = "error";
